<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <transition name="slide">
        <CCard class="border-warning">
          <CCardHeader>
            <strong>Becas por Sede</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-primary ml-2" data-html="true"
                        data-toggle="tooltip" title="Nuevo pago por gestion" type="button"
                        @click="nuevo()">
                  <font-awesome-icon icon="fa-plus"/>
                  Nuevo
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>
              </form>
            </div>
          </CCardHeader>
          <CCardBody class="p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="30"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'"
                     @rowclick="onRowclick($event)"
                     :columnsresize="true"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>

    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalEditar"
        size="lg"
    >
      <div class="row mb-2">
        <input type="hidden" v-model="beca.sede_id"/>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>TIPO DE BECA:</strong></div>
        <div class="col-8">
          <input type="text" class="form-control" v-model="beca.grupo2" maxlength="250" required>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>DESCRIPCIÓN:</strong></div>
        <div class="col-8">
          <input type="text" class="form-control" v-model="beca.descripcion" maxlength="250" required>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>COLEGIATURA (DEL CONVENIO):</strong></div>
        <div class="col-8">
          <input type="number" class="form-control" v-model="beca.valor4" min="0.0" required>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>PORCENTAJE DE DESCUENTO:</strong></div>
        <div class="col-8">
          <input type="number" class="form-control" v-model="beca.valor" min="0.0" max="100.0" required>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>ORDEN:</strong></div>
        <div class="col-8">
          <input type="number" class="form-control" v-model="beca.orden" min="1" max="10" required>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>ESTADO:</strong></div>
        <div class="col-8">
          <select class="form-control form-select" v-model="beca.estado" required>
            <option value="Y">HABILITADO</option>
            <option value="N">DESHABILITADO</option>
          </select>
        </div>
      </div>
      <template #header>
        <h6 class="modal-title">
          <span>Datos de la beca</span>
        </h6>
        <CButtonClose @click="modalEditar = false"/>
      </template>
      <template #footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="modalEditar = false"
        >
          Regresar a la pantalla anterior
        </button>
        <button
            type="button"
            class="btn btn-primary"
            @click="store()"
        >
          Grabar
        </button>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'calendariopagos',
  components: {
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      modalEditar: false,
      beca: {
        id : 0,
        sede_id : localStorage.sedeSeleccionada,
        grupo2:"",
        descripcion : "",
        valor: 0,
        valor4: 0,
        orden : 1,
        estado : 'Y',
      },
      sedeoptions: [],
      gestionoptions: [],
      pagooptions: [],
      mesActual: '',
      items: [],
      toasts: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {text: '', datafield: "id", hidden: true},
        {
          text: 'TIPO DE BECA', datafield: "grupo2", width: '20%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">TIPO DE<br/>BECA</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Descripcion', datafield: "descripcion", width: '30%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Descripción</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Colegiatura convenio', datafield: "valor4", width: '10%',cellsalign:'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Colegiatura<br/>Convenio</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'beca', datafield: "valor", width: '10%', cellsalign:'center',cellsformat: 'p2',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Porcentaje<br/>Beca</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Orden', datafield: "orden", width: '10%', cellsalign:'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Orden</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '12%', cellsalign:'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Estado</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<div class="text-center mt-1 '+(value=='Y'?'':'text-danger text-bold')+'">'+(value=='Y'?'HABILITADO':'DESHABILITADO')+'</div>';
          }
        },
        {
          text: '', width: '8%',  cellsalign:'center',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-primary ml-1 mt-0 pl-1 pr-1" href="/#/inscripcion/' + rowdata.codigo_alumno + '/editar" title="Editar inscripcion de alumno nuevo">Editar</a>';
          }, filterable: false, sortable: false
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/inscripcion/becaslistar?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'grupo2', type: "string"},
        {name: 'descripcion', type: "string"},
        {name: 'valor', type: "float"},
        {name: 'valor4', type: "float"},
        {name: 'orden', type: "int"},
        {name: 'estado', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'grupo2',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    isarray(item) {
      return Array.isArray(item);
    },
    nuevo() {
      this.beca.id = 0;
      this.beca.sede_id = localStorage.sedeSeleccionada;
      this.beca.grupo2 = "";
      this.beca.descripcion = "";
      this.beca.valor= 0;
      this.beca.valor4= 0;
      this.beca.orden = 1;
      this.beca.estado = 'Y';
      this.modalEditar = true;
    },
    onRowclick: function (event) {
      let datos = this.$refs.gridSystem.getrowdata(event.args.rowindex);
      console.log(datos)
      this.beca.id = datos.id;
      this.beca.sede_id = localStorage.sedeSeleccionada;
      this.beca.grupo2 = datos.grupo2;
      this.beca.descripcion = datos.descripcion;
      this.beca.valor= datos.valor;
      this.beca.valor4= datos.valor4;
      this.beca.orden = datos.orden;
      this.beca.estado = datos.estado;
      this.modalEditar = true;
    },
    store() {
      modalPdf.$refs.alert.show("Grabando datos");
      axios.post(this.$apiAdress + '/api/inscripcion/storebeca?token=' + localStorage.getItem("api_token"),
          modalPdf.beca
      )
          .then(function (response) {
            modalPdf.modalEditar = false;
            modalPdf.$refs.gridSystem.updatebounddata();
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Registro creado satisfactoriamente.', 'success');
            modalPdf.$refs.alert.hide();
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));

      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      modalPdf.sede = data;
    });
  },
}
</script>